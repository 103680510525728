<template>
  <div

    @click="removeLists"
  >
    <div
      class="table__search-input"
    >
      <div class="orderSearchBy">
        <!-- <div><p style="margin-top:10px; margin-bottom: 0px; margin-right: 10px; font-weight: 400;">
          {{ $t('PickUpD') }}
        </p></div>
        <b-form-input
          v-model="filterDate"
          type="date"
        /> -->
        <b-form-select
          v-model="selectedOrderType"
          class="custom-select1"
          style="
            padding: 8.5px;
            color: #82868c;
            border-radius: 8px;
            border: none;
            margin-left: 30px;
            box-shadow: none;
          "
          dropup
          :options="options"
        />
        <!-- <b-form-select
          v-model="selectedOrderState"
          class="custom-select2"
          style="
            padding: 8.5px;
            color: #82868c;
            border-radius: 8px;
            margin-left: 30px;
            border: none;
            box-shadow: none;"
          dropup
          :options="stateOptionsFiltered"
        /> -->
      </div>
      <button
        class="backButton"
        @click="goBack"
      >
        {{ $t("Back") }}
      </button>
    </div>
    <section
      class="main"
    >
      <div style="width: 100%;  height: 800px; overflow-y: scroll;">

        <ul
          v-if="getOrders.length > 0"
          class="ul-order"
        >
          <div
            class="order-card header"
          >
            <p style="width: 50%">
              <span style="color:#262E6C;font-weight: bold"> {{ $t('OrderNumber') }}</span> <br>
            </p>
            <p style="width: 50%">
              <span style="color:#262E6C;font-weight: bold">{{ $t('OrderType') }}</span> <br>

            </p>
            <p style="width: 70%">
              <span style="color:#262E6C;font-weight: bold">{{ $t('CustomerName') }}</span> <br>

            </p>
            <p style="width: 100%">
              <span
                v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'Manager' || getLoggedInUser.role == 'Receptionist'"
                style="color:#262E6C;font-weight: bold"
              >{{ $t('Paid1') }} / {{ $t('Price') }}</span> <br>

            </p>
            <p style="width: 60%;">
              <span style="color:#262E6C;font-weight: bold">{{ $t('PhoneNumber') }}</span> <br>

            </p>
            <!-- <p style="width: 100%;word-break: break-all">
              <span style="font-weight: 600">{{ $t('BillingAddress') }}:</span> <br>
              <span

                style="margin-right: 10px"
              />
            </p> -->
            <p

              style="width: 100%;"
            >
              <span style="color:#262E6C;font-weight: bold;">{{ $t('ShippingAddress') }}</span> <br>
              <span

                style="margin-right: 10px"
              />
            </p>
            <p

              style="width: 50%"
            >
              <span style="color:#262E6C;font-weight: bold">{{ $t('Created') }}</span> <br>
              <span style="margin-right: 10px" />
            </p>
            <p style="text-align: left;width: 50%;">
              <span style="color:#262E6C;font-weight: bold">{{ $t('Items') }} </span>
            </p>
          </div>
          <li
            v-for="(item, index) in getOrders"
            :key="item.orderNumber"
            :ref="`orderItem-${item.orderNumber}`"
            class="hover-row"
            :class="{ 'margin-top': index === 0 }"
          >
            <div
              class="order-card"
              @click="readOrderById(item.orderNumber)"
            >
              <p style="width: 50%;font-weight: 600">
                <br>{{ item.orderNumber }}
              </p>
              <p style="width: 50%;font-weight: 600">
                <br>
                {{ $t(item.orderType) }}
              </p>
              <p style="width: 70%;font-weight: 600">
                <br>
                {{ item.ordererResponseDto.firstName }} {{ item.ordererResponseDto.lastname }}
              </p>
              <div
                v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin' || getLoggedInUser.role == 'Manager' || getLoggedInUser.role == 'Receptionist'"
                style="width: 100%;word-break: break-all;;font-weight: 600"
              >
                <br>
                <p
                  style="display: inline; margin-right: 30px; cursor: pointer"
                >
                  <span
                    v-b-modal.price-paid-modal
                    style="background: #FF274F !important;
                    color: #f4f1ed;
                    border-color: transparent; padding: 10px 10px 10px 10px; border-radius: 5px; border-color: transparent;display: flex;
                        width: 50%;
                        justify-content: center;
                    }"
                    @click="priceTrack(item.orderNumber)"
                  > {{ item.paidTotal }}€ / {{ item.priceTotal }}€  </span>
                </p>
              </div>
              <div
                v-else
                style="width: 100%;word-break: break-all;;font-weight: 600"
              >
                <br>
                <p
                  style="display: inline; margin-right: 30px; cursor: pointer"
                  @click="priceTrack(item.orderNumber)"
                >
                  <span> {{ item.ordererResponseDto.email }}  </span>
                </p>
              </div>
              <p style="width: 60%;font-weight: 600">
                <br>
                <!-- {{ item.ordererResponseDto.countryCode }}{{ item.ordererResponseDto.telephoneNumber }} -->
                +{{ countryCallingCode[item.ordererResponseDto.countryCode] }} {{ item.ordererResponseDto.telephoneNumber }}
              </p>
              <p style="width: 100%;word-break: break-all;font-weight: 600">
                <br>
                <span
                  v-for="i in item.billingAddressDto"
                  :key="i.id"
                  style="margin-right: 10px"
                >{{
                  i
                }}</span>
              </p>
              <p
                v-if="item.pickUpInStore == false"
                style="width: 100%;word-break: break-all;font-weight: 600"
              >
                <br>
                <span>{{ item.created }}</span>
              </p>
              <p
                v-else
                style="width: 50%;font-weight: 600"
              >
                <br>
                <span style="margin-right: 10px"> {{ modifyDate(item.created) }} </span>
              </p>
              <p style="text-align: left;width: 50%;;font-weight: 400; display: flex; align-items: center">
                {{ item.itemCounter }}
              </p>
            </div>
            <ul
              v-if="showHide == true && activeOrderStatus == item.orderNumber"
              style="border-top: 1px solid grey"
            >
              <li
                v-for="itemz in getArticleOrders"
                v-show="item.orderNumber === activeOrderStatus"
                :key="itemz.created"
                style="padding: 20px;"
              >
                <p
                  v-if="getLoggedInUser.role != 'TeamLeader' || getLoggedInUser.role != 'StockManager'"
                  style="display: inline; margin-right: 30px; cursor: pointer"
                  @click="trackOrder(item.orderNumber, itemz.orderItemId)"
                >
                  <span style="color:#262E6C;font-weight: 400;">{{ $t('ArticleName') }}: </span> <span
                    v-b-tooltip.hover
                    :title="$t('Tracking')"
                    style="background: #FF274F !important;
                    color: #f4f1ed;
                    border-color: transparent; padding: 10px 10px 10px 10px; border-radius: 5px; border-color: transparent;"
                  >{{ itemz.articleNumber }}</span>
                </p>
                <p
                  v-if="getLoggedInUser.role === 'TeamLeader'"
                  style="display: inline; margin-right: 30px;"
                >
                  <span style="color:#262E6C;font-weight: 400;">{{ $t('ArticleName') }}</span> <span>{{ itemz.articleNumber }}</span>
                </p>
                <p style="display: inline; margin-right: 30px">
                  <span style="color:#262E6C;font-weight: 400;">{{ $t('AssignedTeam') }}:</span>
                  {{
                    itemz.teamName !== "" && itemz.processingUser === ""
                      ? itemz.teamName
                      : itemz.processingUser != ""
                        ? `${itemz.teamName}, ${itemz.processingUser}`
                        : $t('NoTeamAssigned')
                  }}
                </p>
                <p style="display: inline; margin-right: 30px">
                  <span style="color:#262E6C;font-weight: 400;">{{ $t('PickupDate') }}:</span>
                  {{ modifyDate(itemz.pickUpDate.substring(0, 10)) }}
                </p>
                <button
                  v-b-modal.view-orderItem-details
                  class="btn btn-outline-primary"
                  type="button"
                  style="margin-right: 20px"
                  @click="loadS(itemz.orderItemId),
                          loadAllDetails(itemz.orderItemId),
                          loadDescription(item.orderNumber),
                          loadM(item.orderNumber, itemz.orderItemId),
                          loadOrders(itemz.orderItemId),
                          loadOrderImages(item.orderNumber)"
                >
                  {{ $t('Show') }} {{ $t('Details') }}
                </button>
                <img
                  v-b-tooltip="$t('ClickToViewPhoto')"
                  style="height: 40px; border-radius: 6px; cursor: pointer;"
                  src="@/assets/images/imageicon.svg"
                  alt=""
                  @click.stop="getAllImages(itemz.articleNumber)"
                >
              </li>
            </ul>
          </li>
        </ul>
        <div
          v-else
          style="text-align: left; font-size: 20px; box-shadow: none; padding: 6px 0"
        >
          <p style="margin: 0">
            {{ $t('NoOrderWasFound') }}!
          </p>
        </div>
      </div>
    </section>
    <div style="padding: 0px 15px 10px 0px">
      <b-pagination
        v-model="page"
        :total-rows="getTotalItemsForOrders"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <ViewAllDetailsOrderItem :order-item-id="orderItemId" />
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      @hide="handleHide"
    />
    <PriceAndPaidModal
      :order-item-id="orderItemId"
      :order="orderNumberr"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import VueEasyLightbox from 'vue-easy-lightbox'
import PriceAndPaidModal from '@/components/ordersOverview/modals/PriceAndPaidModal.vue'
import { countryCallingCodes } from '../../countryCodes'
import ViewAllDetailsOrderItem from '../../modals/ViewAllDetailsOrderItem.vue'
import { client } from '../../domainConfig'

export default {
  name: 'Home',
  components: { ViewAllDetailsOrderItem, VueEasyLightbox, PriceAndPaidModal },
  // eslint-disable-next-line vue/require-prop-types
  props: ['state', 'orderToScroll', 'clientName', 'pageNumber', 'order'],
  data() {
    return {
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      orderItemId: null,
      client: client.clientName,
      countryCallingCode: countryCallingCodes,
      toggleSarch: true,
      show: true,
      showM: false,
      showS: false,
      showDescription: false,
      showHide: false,
      orderNumberr: null,
      searchCN: '',
      onSearchTwo: false,
      searchON: '',
      arrowCounter: 0,
      clientId: '',
      arrowCounter2: 0,
      noSearch: true,
      orderNumber: null,
      customerName: null,
      filterDate: '',
      countS: 0,
      rows: [],
      activeId: '',
      orderStatus: '',
      activeOrderStatus: '',
      das: '',
      dasSize: '',
      dasDescription: '',
      description: '',
      orders: [],
      page: 1,
      pageSize: 15,
      selectedOrderType: null,
      options: [
        { value: null, text: this.$t('All') },
        { value: 'InStore', text: this.$t('InStore') },
        { value: 'Online', text: 'Online' },
        { value: 'WholeSale', text: this.$t('WholeSale') },
        { value: 'Instagram', text: 'Instagram' },
        { value: 'Whatsapp', text: 'Whatsapp' },
        { value: 'Viber', text: 'Viber' },
        { value: 'Facebook', text: 'Facebook' },
      ],
      selectedOrderState: null,
      stateOptions: [
        { value: null, text: this.$t('All') },
        { value: 'Created', text: this.$t('Created') },
        { value: 'Assigned', text: this.$t('Assigned') },
        { value: 'Confirmed', text: this.$t('Confirmed') },
        { value: 'InProduction', text: this.$t('InProduction') },
        { value: 'InSewing', text: this.$t('InSewing') },
        { value: 'InHandSewing', text: this.$t('InHandSewing') },
        { value: 'QualityPassed', text: this.$t('QualityPassed') },
        { value: 'ReadyToDeliver', text: this.$t('ReadyToDeliver') },
        { value: 'Delivered', text: this.$t('Delivered') },
        { value: 'StockNotAvailable', text: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', text: this.$t('QualityCheckNotPassed') },
      ],
      stateOptionsDeliverer: [
        { value: 'ReadyToDeliver', text: 'ReadyToDeliver' },
        { value: 'Delivered', text: 'Delivered' },
      ],
      stateOptionsTeamLeader: [
        { value: 'Assigned', text: 'Assigned' },
        { value: 'Confirmed', text: 'Confirmed' },
        { value: 'InProduction', text: 'InProduction' },
        { value: 'InSewing', text: 'InSewing' },
        { value: 'InHandSewing', text: 'InHandSewing' },
        { value: 'QualityPassed', text: 'QualityPassed' },
        { value: 'ReadyToDeliver', text: 'ReadyToDeliver' },
        { value: 'Delivered', text: 'Delivered' },
        { value: 'StockNotAvailable', text: this.$t('StockNotAvailable') },
        { value: 'QualityCheckNotPassed', text: this.$t('QualityCheckNotPassed') },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getOrderTypes',
      'getimagesOrder',
      'getOrders',
      'getTotalItemsForOrders',
      'getArticleOrders',
      'getMaterialVariantsOfOrder',
      'getLoggedInUser',
      'getFilteredOrdersbyON',
      'getFilteredOrdersbyCN',
      'getIsLoading',
      'getTwentyPrinciples',
      'getOrderItemSize',
      'getimagesProductionManagement',
      'getDescriptionOfOrder',
      'getOrderItemStates',
      'getLanguage',
    ]),
    stateOptionsFiltered() {
      const uniqueOrderItemStates = this.getOrderItemStates;
      const options = uniqueOrderItemStates.map((state) => {
        return {
          value: state,
          text: this.$t(state),
        }
      });
      const allOption = { value: null, text: this.$t('All') };
      const filteredOptions = [allOption, ...options];

      return filteredOptions;
    },
    transformOrderTypes() {
      const allOption = { value: null, text: this.$t('All'), orderType: 'all' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        };
      });
      const websiteOrderType = {
        value: 'Website',
        text: 'Website',
        orderType: 'Website',
      };
      return [allOption, ...orderTypes, websiteOrderType];
    },
  },
  watch: {
    page(value) {
      this.getOrdersOverview({
        pageNumber: value,
        pageSize: this.pageSize,
        clientId: this.clientId,
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      });
    },
    selectedOrderType(value) {
      if (value == null) {
        // eslint-disable-next-line no-useless-return
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: null,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        });
        // eslint-disable-next-line no-else-return
      } else {
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: value,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        });
      }
    },
    filterDate(value) {
      if (value == '') {
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: null,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        });
      } else {
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: value,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        });
      }
    },
    selectedOrderState(value) {
      if (value == null) {
        // eslint-disable-next-line no-useless-return
        this.$router.push({ params: { state: 'all' } });
        this.state = null;
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: value,
        });
        // eslint-disable-next-line no-else-return
      } else {
        this.state = value;
        this.$router.push({ params: { state: value } });
        this.showHide = false
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: value,
        });
      }
    },
    orderToScroll(newValue) {
      this.scrollToOrder(newValue);
    },
  },
  // props: ['state', 'orderToScroll', 'clientName', 'pageNumber', 'clientId', 'order'],
  created() {
    // Access the client ID parameter from the route
    this.clientId = this.$route.params.id;
  },
  mounted() {
    this.clientId = this.$route.params.id
    if (this.pageNumber) {
      this.page = this.pageNumber;
    }
    if (this.clientId) {
      this.getOrdersOverview({
        pageNumber: 1,
        pageSize: this.pageSize,
        clientId: this.clientId,
        pickUpDate: null,
        orderType: null,
        orderItemState: null,
      });
    }
    if (this.orderToScroll) {
      this.scrollToOrder(this.orderToScroll);

      this.activeOrderStatus = this.orderToScroll;
      this.showHide = true;
    }
  },
  methods: {
    ...mapActions([
      'getOrdersOverview',
      'readOrderItems',
      'materialVariantsByOrderNAndArticleN',
      'resetDescriptionOfOrder',
      'loadDescriptionOfOrder',
      'resetMaterialVariantsOfOrders',
      'searchByOrderNumber',
      'fshij',
      'searchByCustomerName',
      'fshijCN',
      'changeLoadingtoTrue',
      'loadOrderTrackByOnAndAn',
      'resetMaterialVariantsByOrderNAndArticleN',
      'loadTwentyPrinciplesOrSize',
      'resetTwentyPrinciplesOrSize',
      'loadOrderTypes',
      'loadProbeDates',
      'loadPrices',
      'getFileByArticleNumber',
      'getFileByOrderNumber',
      'resetReadOrderItems',
      'loadOrderItemLocation',
      'loadOrderItemPaymentTrackByOrderNumber',
      'resetOrderItemTrack',
    ]),
    priceTrack(value) {
      this.resetOrderItemTrack()
      this.loadOrderItemPaymentTrackByOrderNumber({ orderNumber: value })
      this.orderNumberr = value
    },
    goBack() {
      this.$router.push({
        path: '/clients',
        query: {
        },
      });
    },
    scrollToOrder(orderNumber) {
      const orderItemRef = this.$refs[`orderItem-${orderNumber}`];
      if (orderItemRef) {
        orderItemRef[0].scrollIntoView({ behavior: 'smooth' }); // Scroll to the order item
      }
    },
    scrollToPoint() {
      const orderToScroll = this.orderToScroll;
      const targetElement = this.$refs.orderToScrollTo;

      console.log('targetElement:', targetElement);

      if (targetElement && orderToScroll) {
        this.$nextTick(() => {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)


        await this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    async fetchh() {
      try {
        await this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          clientId: this.clientId,
          pickUpDate: this.filterDate == '' ? null : this.filterDate,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    loadAllDetails(orderItem) {
      this.orderItemId = orderItem;
      this.loadProbeDates(orderItem);
      this.loadPrices(orderItem)
    },
    async loadOrderImages(value) {
      this.$store.commit('SET_IMAGES_ORDER', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      await this.getFileByOrderNumber(value);
      this.image.imgs = []
      for (let i = 0; i < this.getimagesOrder.length; i++) {
        const image = this.getimagesOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
    },
    async getAllImages(value) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      await this.getFileByArticleNumber(value);
      this.image.imgs = []
      for (let i = 0; i < this.getimagesProductionManagement.length; i++) {
        const image = this.getimagesProductionManagement[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.showPhoto()
    },
    showPhoto() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
    },

    async trackOrder(on, oi) {
      console.log(oi, on)
      await this.loadOrderTrackByOnAndAn({
        orderNumber: on,
        orderItemId: oi,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {
          this.$router.push({
            path: '/order-item-tracking',
            query: {
              page: this.page,
              client: this.clientId,
              clientName: this.searchCN,
              orderer: this.searchON,
            },
          })
        },
      })
    },
    async readOrderById(ordNId) {
      this.showDescription = false

      // this.changeLoadingtoTrue(false)


      if (this.activeOrderStatus === ordNId && this.showHide) { // Condition to skip request
        this.activeOrderStatus = ''
        this.showHide = false
      } else {
        this.activeOrderStatus = ordNId
        this.showHide = true
        this.resetReadOrderItems()
        await this.readOrderItems({
          orderNumber: ordNId,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        })
      }

      // this.changeLoadingtoTrue(true)
      this.resetMaterialVariantsOfOrders()
      this.showM = false
    },
    showOrderNumber() {
      this.toggleSarch = false;
      this.show = false
      this.searchCN = null;
      this.clientId = null;
      this.getOrdersOverview({
        pageNumber: this.page,
        pageSize: this.pageSize,
        clientId: this.clientId,
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      })
    },
    showCustomerName() {
      this.toggleSarch = true;
      this.show = true
      this.searchON = null;
      this.getOrdersOverview({
        pageNumber: this.page,
        pageSize: this.pageSize,
        clientId: this.clientId,
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      })
    },
    loadDescription(value) {
      this.resetDescriptionOfOrder()
      this.loadDescriptionOfOrder(value)
    },
    loadM(orderN, orderItemUnik) {
      this.resetMaterialVariantsByOrderNAndArticleN()
      this.materialVariantsByOrderNAndArticleN({
        orderNumber: orderN,
        orderItemId: orderItemUnik,
      })
    },
    loadS(orderItemUnik) {
      this.resetTwentyPrinciplesOrSize()
      this.loadTwentyPrinciplesOrSize(orderItemUnik)
    },
    async loadOrders(value) {
      await this.loadOrderItemLocation({
        orderItemId: value,
        articleNumber: null,
        clientId: null,
      })
    },
    modifyDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    removeLists() {
      this.noSearch = false;
      this.noSearchTwo = false;
      this.fshijCN();
      this.fshij();
    },
  },
};
</script>

<style scoped lang="scss">

.main{
  padding: 0 15px 15px 145px;
}

.table__search-input{
  margin-top: 13px;
  margin-left: 40px;
  padding: 110px 15px 15px 115px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.orderSearchBy{
  display: flex;
  justify-content: space-between
}
.margin-top {
  margin-top: 60px;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.hr{
background-color: grey;
min-width: 201px;
height: 1px;
// transform: matrix(0, 1, -1, 0, 0, 0);
/* UI Properties */
border: 0.5px solid #A5A5A5;
opacity: 0.34;
}
.button{
  width: 292px;
  height: 39px;
  border-radius: 0.25rem !important;
  margin-right: 20px;
}
.dropdrop::-webkit-scrollbar {
  display: none;

}
.is-active {
  background-color: #dedede;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}
.material-info {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 margin-bottom: 10px;
 border: none;
 box-shadow: none;
 padding: 12px;
 color: #212529;
 border-radius: 8px;
 width: 24%;
 margin-right: 10px;
}
.material-infoDescription{
  display: flex;
 flex-direction: column;
 justify-content: space-between;
 margin-bottom: 10px;
 border: none;
 box-shadow: none;
 padding: 12px;
 color: #212529;
 border-radius: 8px;
 width: 79%;
 margin-right: 10px;
}
.size-info {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 margin-bottom: 10px;
 border: none;
 box-shadow: none;
 padding: 5px 15px;
 color: #212529;
 border-radius: 8px;
 width: auto;
 margin-right: 10px;
}
.size-info p {
  margin: 0 !important
}
.material-info:last-child {
  margin-right: 0 !important;
}
.material-info.failed2 {
  background-color: rgb(209, 53, 53);
  opacity: 0.7;
  color: white;
}
input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.pm-li {
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 12px 10px;
  margin-top: 20px;
}
.pm-li h5 {
  margin-bottom: 0;
}
.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
.ul-order li {
  background: #ffffff;
  overflow-y: auto;

  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 6px 2px 6px 10px;
  // margin-top: 15px;
}
.ul-order ul {
  padding-left: 5px;
}

.order-card {
  cursor: pointer;
  display: flex;
}

.order-card p {
  margin-right: 20px;
}
.iconat{
  width:80px;
  height: 80px;
}
.second-div{
  display: none;
}
.stage-card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 10px;
  margin-bottom: 15px;

  margin-right: 10px;
}
.stage-card.current {
  background-color: #b6ffb6;
}
.stage-card.activee {
  background-color: grey;
  opacity: 0.7;
  color: #b6ffb6;
  background: #FF274F !important;
}
.stage-card.failed2 {
  background-color: rgb(209, 53, 53);
  opacity: 0.7;
  color: white;
}
.active2{
  color: $base-color;
  font-weight: bold;
}
.stage-card.failed {
  background-color: rgb(209, 53, 53);
  opacity: 0.7;
  color: white;
}

.order-card{
  justify-content: space-between;
}

.header{
  width:90.5%;
  justify-content: space-between;
  padding: 6px 2px 6px 10px;
  background-color: white;
  height: 50px; position: absolute;
}

@media screen and (max-width: 1440px){
  .main {
    padding: 0 15px 15px 100px !important;
  }

  .table__search-input{
    padding: 110px 15px 15px 60px;
  }
  .search1{
  display: none;
}
.iconat{
  width:35px;
  height: 35px;
}
  .first-div{
    display: none;

  }
  .second-div{
    display: block;
  }
    .stage-card{
    width: 300px;
  }
}

@media screen and (max-width: 1300px) {
  .input2 {
    width: 160px !important;
    margin-left: 0px;
    font-size: smaller;
    margin-left: 4px !important;
    padding-left: 0px;
    text-align: end;
  }
  .input1 {
    width: 150px !important;
    margin-left: 0px;
    font-size: smaller;
  }
  .search1 {
    padding: 0px;
  }
  .custom-select1 {
    width: 80px !important;
    font-size: small;
  }
  .custom-select2 {
    width: 100px !important;
    font-size: small;
    padding-left: 0px;
    margin-left: 4px !important;
  }
  p {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1340px){
  .table__search-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 120px 0.75rem 15px 90px!important;
  }

  .search1{
    left: 328px !important;
    top: 83% !important;
  }
  .main {
    padding: 0 15px 15px 85px !important;
    /* justify-content: center; */
  }
}
@media screen and (max-width: 1190px) {
  .table__search-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 150px 0.75rem 15px 0.75rem !important;
  }

  .search1{
    left: 328px;
    top: 83%;
  }

  .header{
    width: 98%;
  }
  .main {
    padding: 0px 0.75rem 0 0.75rem !important;
    /* justify-content: center; */
  }
  .order-card {
    // flex-wrap: wrap;
    p {
      // flex-basis: 30%;
    }
  }
 }
@media only screen and (max-width: 800px) {
.material-con {
  // flex-direction: column;
  .material-info {
    // width: 100%;
    flex-basis: 40%;
  }
}
.table__search-input{
  margin-top: 13px;
  margin-left: 40px;
  padding: 110px 15px 15px 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orderSearchBy{
  margin-bottom: 10px;
}

.search1{
  display: none;
}
}

@media screen and (max-width: 1100px){
  .search1{
    display: none;
  }
}

@media screen and (max-width: 1200px){
  .table__search-input{
    padding: 111px 15px 15px 65px;
  }

  .main{
  padding: 0 15px 15px 100px;
  }

  .search1{
    display: none;
  }
}
.backButton {
  /* Button styles */
  background: linear-gradient(52deg, rgb(38, 46, 108) 0%, rgb(155, 42, 92) 100%, rgb(255, 39, 79) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    color: rgb(244, 241, 237);
  padding: 10px 80px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  // width: 200px;
  // margin-left: 75px;
  text-decoration: none;
  // margin-top: 70px;
}
.backButton:hover {
  /* Hover styles */
  background-color: #0056b3;
}

.backButton:active {
  /* Active (clicked) styles */
  background-color: #003080;
}
</style>
